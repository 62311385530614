
import { Options } from "vue-class-component";
import ComponentMixins from "../ComponentMixins";
import FormDesignRender from "../../../setting/form-setting/FormDesignRender/FormDesignRender.vue";

@Options({
    name: "flowable-common-form-components-span-layout",
    components: { FormDesignRender },
    props: {
        items: {
            type: Array,
            default: (): any => {
                return [];
            },
        },
    },
})
export default class SpanLayout extends ComponentMixins {
    private items: any;
    private select: any = null;
    private drag = false;
    private formConfig: any = {
        //数据字段
        data: {},
        //校验规则
        rules: {},
    };
    private form: any = {
        formId: "",
        formName: "",
        logo: {},
        formItems: [],
        process: {},
        remark: "",
    };
    get _items() {
        return this.items;
    }
    set _items(val: any) {
        this.items = val;
    }
    get __items() {
        let result = [];
        for (let i = 0; i < this.items.length; i++) {
            if (i > 0 && i % 2 > 0) {
                result.push([this.items[i - 1], this.items[i]]);
            }
        }
        if (result.length * 2 < this.items.length) {
            result.push([this.items[this.items.length - 1]]);
        }
        return result;
    }
    get selectFormItem() {
        return this.$store.getters["flowable/selectFormItem"];
    }
    set selectFormItem(val: any) {
        this.$store.dispatch("flowable/setSelectFormItem", val).then();
    }
    get nodeMap() {
        return this.$store.getters["flowable/nodeMap"];
    }
    set nodeMap(val: any) {
        this.$store.dispatch("flowable/setNodeMap", val).then();
    }
    public selectItem(cp: any) {
        this.selectFormItem = cp;
    }
    public getSelectedClass(cp: any) {
        return this.selectFormItem && this.selectFormItem.id === cp.id ? "border-left: 4px solid #f56c6c" : "";
    }
    public delItem(index: any) {
        this.$vueAlert({
            title: "提示",
            message: "删除组件将会连带删除包含该组件的条件以及相关设置，是否继续?",
            closeButtonName: "取消",
            submitButtonName: "确定",
        }).then(() => {
            if (this._items[index].name === "SpanLayout") {
                //删除的是分栏则遍历删除分栏内所有子组件
                this._items[index].props.items.forEach((item: any) => {
                    this.removeFormItemAbout(item);
                });
                this._items[index].props.items.length = 0;
            } else {
                this.removeFormItemAbout(this._items[index]);
            }
            this._items.splice(index, 1);
        });
    }
    public async removeFormItemAbout(item: any) {
        this.nodeMap.forEach((node: any) => {
            //搜寻条件，进行移除
            if (node.type === "CONDITION") {
                node.props.groups.forEach((group: any) => {
                    let i = group.cids.remove(item.id);
                    if (i > -1) {
                        //从子条件移除
                        group.conditions.splice(i, 1);
                    }
                });
            }
            //搜寻权限，进行移除
            if (node.type === "ROOT" || node.type === "APPROVAL" || node.type === "CC") {
                node.props.formPerms.removeByKey("id", item.id);
            }
        });
    }
}
